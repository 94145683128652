<template>
    <div>
        <input type="file" name="file" id="file">
        <el-button type="primary" @click="initUpload(0)">upload</el-button>
        <el-button type="success" @click="mergeSlice">合并</el-button>
        <div>
            <el-progress :percentage="percent"></el-progress>
        </div>
    </div>
</template>

<script>
import  Upload from '@/utils/slice_upload';
import { sliceUpload, uploadMaterial, mergeSlice } from "@/utils/apis";

export default {
    name: "Upload",
    data() {
        return {
            percent: 0,
            flag: '',
            uploadInstance: null,

        }
    },
    methods: {
        initUpload(index) {
            let chunkSize = 10*1024*1024;
            let fileElement = document.querySelector('#file');
            let file = fileElement.files[0];
            this.uploadInstance = new Upload(file, chunkSize, sliceUpload);
            //上传分片
            this.uploadSlice(index);
        },
        uploadSlice(index) {
            if (index === this.uploadInstance.total_block_num && this.uploadInstance.total_block_num !== 1) {
                //合并分片
                this.mergeSlice();
                return;
            }
            this.uploadInstance.uploadSlice(index).then(res => {
                if (res.code === 200) {
                    if (res.data.is_slice) { //is_slice 为true则为分片上传，后面需要合并分片,若为false则无须分片，或者文件大小<分片尺寸，直接上传就行
                        this.percent = Math.ceil((index/this.uploadInstance.total_block_num)*100);
                        this.uploadSlice(++index);
                    } else {
                        this.percent = 100;
                        this.$message.success(`${res.msg};文件访问路径:${localStorage.getItem('hosturl')}${res.data.src}`);
                    }

                }
            });
        },
        mergeSlice() {
            mergeSlice({flag: this.uploadInstance.file_tmp, slice_size: this.uploadInstance.block_size}).then(res => {
                if (res.code === 200) {
                    this.percent = 100;
                    this.$message.success(`${res.msg};文件访问路径:${localStorage.getItem('hosturl')}${res.data.src}`);

                }
            });

        }
    }
}
</script>

<style scoped>

</style>